import React, {useState, Suspense} from 'react'
import {Formiz, FormizStep, useForm} from '@formiz/core';
import {Box, Fade, Grid, Stack} from '@chakra-ui/react';
import {PageLayout} from '../layout/PageLayout';

// Step Buttons
import {NextButton} from './buttons/NextButton';
import {PreviousButton} from './buttons/PreviousButton';
import {DotsStepper} from './utils/DotStepper';

import moment from 'moment';
import 'moment/locale/de';

// Steps
const UserGoals = React.lazy(() => import('./steps/UserGoals'));
const UserDetails = React.lazy(() => import('./steps/UserDetails'));
const Complete = React.lazy(() => import('./steps/Complete'));

const StepperButtons = () => (
    <Stack>
        <Box mt="5">
            <Grid templateColumns="1fr 1fr">
                <Box>
                    <PreviousButton/>
                </Box>
                <Box textAlign="right">
                    <NextButton/>
                </Box>
            </Grid>
        </Box>
    </Stack>
);

export const Stepper = () => {
    const form = useForm({subscribe: 'form'});
    const [formValues, setFormValues] = useState({});

    const [startDate, setStartDate] = useState('');
    const [estimatedEndDate, setEstimatedEndDate] = useState('');
    const [estimatedEndDays, setEstimatedEndDays] = useState(0);

    const [totalDaysCounter, setTotalDaysCounter] = useState('');

    moment.locale('de');

    const handleSubmit = (values) => {
        setFormValues(values);

        window.scrollTo(0, 0);

        const fitnessCalculator = require('fitness-health-calculations');

        let tDee = fitnessCalculator.tdee(
            'male',
            parseInt(values.age),
            parseInt(values.body_size),
            parseInt(values.weight),
            'moderate'
        );

        let estimatedDays = Math.round((values.weight - values.targetWeight) * 7000 / tDee);
        let estimatedEndDate = moment().add(estimatedDays, 'days');

        let startDate = moment(estimatedEndDate).subtract(7, 'days');

        setEstimatedEndDays(estimatedDays);
        setStartDate(startDate.format('Do MMMM YYYY'));
        setEstimatedEndDate(estimatedEndDate.format('Do MMMM YYYY'));

        setTotalDaysCounter(estimatedEndDate.diff(moment(), 'days'));
    };

    let currentDate = moment().format('Do MMMM YYYY');

    return (
        <Formiz connect={form} onValidSubmit={handleSubmit}>
            {form.isSubmitted ?
                <Suspense fallback={<></>}>
                    <Fade in={true} delay={0.1}>
                        <Box>
                            <Complete
                                weight={formValues.weight}
                                targetWeight={formValues.targetWeight}
                                weightDiff={(formValues.weight - formValues.targetWeight)}
                                startDate={startDate}
                                currentDate={currentDate}
                                estimatedEndDate={estimatedEndDate}
                                daysToEstimatedEndDate={estimatedEndDays}
                                totalDaysCounter={totalDaysCounter}
                            />
                        </Box>
                    </Fade>
                </Suspense>
                :
                <PageLayout>
                    <form noValidate onSubmit={form.submitStep}>
                        <Box className="stepDots-container" p="4" borderRadius="md" mb="6">
                            <DotsStepper/>
                        </Box>
                        <FormizStep name="step1">
                            <Suspense fallback={<></>}>
                                <UserDetails/>
                            </Suspense>
                        </FormizStep>

                        <FormizStep name="step2">
                            <Suspense fallback={<></>}>
                                <UserGoals/>
                            </Suspense>
                        </FormizStep>

                        <Stack spacing="6" mt="8">
                            <StepperButtons title="Dots stepper"/>
                        </Stack>
                    </form>
                </PageLayout>
            }
        </Formiz>

    )
}
