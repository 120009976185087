import React from 'react';
import { Box, Badge, Container, SimpleGrid, Heading, Text } from '@chakra-ui/react';

import logo from '../assets/img/logo.svg';

export const PageHeader = () => {
    return (
        // <BackgroundVideo>
            <Box className="header-container" w="100%" py={20}>
                <Container maxW="container.lg">
                    <SimpleGrid columns={[1, null, 2]}>
                        <Box className="header-inner-contents">
                            <Box id="logo" w="100%" mb={10}>
                                <img className="m4" width="80" height="80" src={logo} alt="logo" />
                            </Box>

                            <Badge fontSize="1em" mb={2}>Jetzt neu!</Badge>
                            <Heading mb={5}>Indivduelle Prognose für den Gewichtsverlust</Heading>
                            <Text fontSize={20}>Die LIPTAN-Anwendung ist erfolgreich und durch Studien belegt. Auf dieser Datengrundlage können wir die Zeit berechnen, die Sie für die Erreichung Ihres gewünschten Gewichts vermutlich benötigen.</Text>
                        </Box>
                    </SimpleGrid>
                </Container>
            </Box>
        //</BackgroundVideo>
    )
}
